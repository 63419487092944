<template>
  <div class="compaProfiCss">
    <Navbar :pageName="pageName" />
    <div class="contentMsgCss">
      <div class="leftMsgCss">
        <div v-for="(item, index) in profiList" :key="index">
          <div class="titleCss">{{item.title}}</div>
          <div class="msgCss" v-for="(msg, i) in item.msgList" :key="i">{{msg}}</div>
          <img v-for="(url, i) in item.imgs" :key="i" :src="url" />
          <div v-if="item.titleAndMsgList" class="titleAndMsgCss">
            <div class="tAmItemCss" v-for="(tAmItem, tAmIndex) in item.titleAndMsgList" :key="tAmIndex">
              <div class="tAmTitleCss">{{ tAmItem.title }}</div>
              <div class="tAmMsgCss">{{ tAmItem.msg }}</div>
            </div>
            <br style="clear:both" />
          </div>
        </div>
      </div>
      <div class="rightMsgCss">
        <div class="borderLineCss" />
        <div class="labelContentCss">
          <div class="labelCss" v-for="(item,index) in labelList" :key="index" @mouseenter="labelMouseenter(item)" @mouseleave="labelMouseleave(item)" @click="clickLabel(item)">
            {{item.name}}
            <div class="iconContentCss">
              <svg-icon icon-class="right"  width="0.8rem" height="0.8rem" :color="item.iconColor" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'

export default {
  name: 'newsCent',
  components: {
    Navbar
  },
  data() {
    return {
      pageName: '',
      profiList: [
        {
          title: '公司简介',
          msgList: [
            '成都帝图信息技术有限公司总部设立在成都市高新区，是一家技术专业的装备信息化方案和服务提供商。作为一家集研发、制造与销售为一体的高科技企业，拥有专业的软硬件研发工程师团队和完善的软硬件研发体系。',
            '公司始终专注于物联网技术、云计算技术和嵌入式技术的研究与应用，坚持以客户需求为导向，不断积累物联网系统工程的开发与项目实施经验。公司主要面向设备类用户提供物联网“装备云”平台和物联网智能采集终端产品，旨在打破目前制造业普遍存在的信息孤岛，解决设备广泛联网和数据分析应用的难题',
            '公司目前已在北京中关村科技核心区设立研发中心，与移动、联通、电信等电信运营商以及西门子、罗克韦尔等优秀工控厂商建立了广泛的合作。公司以自主创新和追求卓越为己任，以严谨的态度保障科学研发和质量稳定，始终保持工业物联网行业的竞争优势。',
            '公司拥有清华大学、中科院、北京理工大学等国家重点研究机构的工程师数十人，且拥有丰富的物联网研发经验和项目实施经验，在机器信息化领域有着良好的人才优势。目前，团队耗费5年时间研发的“设备数字化远程运维系统”已获得《物联网工业云远程管理平台》等4项软件著作权和《基于4G全网通的物联网智能采集终端》、《基于NB-IOT技术的物联网智能采集终端》等多项专利。',
            '公司努力帮助新能源装备、电气装备、医疗设备、压缩机、数控机床、水处理设备、消防设备、燃气设备、工业机器人等设备制造商或环保设施运营商，搭建设备远程监控运维诊断系统，提高设备的维护效率和运营效率，节省企业成本投入。在传统企业纷纷转型的物联网时代，帝图将勇于探索、持续创新，为行业客户带来实实在在的核心价值，助力企业成功转型！'
          ]
        },
        {
          title: '企业文化',
          titleAndMsgList: [
            { title: '【使命】', msg: '让数据拥有生命力，为用户持续创造数据价值体验，用数据思维改造人类运营生产场景中普遍存在的低效点，提供极具创新、高质量的智能产品。' },
            { title: '【愿景】', msg: '致力于成为以场景算法为核心驱动力的数据科技企业。整合、创新技术，为用户带来极致体验。' },
            { title: '【价值观】', msg: '以用户需求和体验为导向，提倡务实、诚信、创新、高效协作的团队文化。' },
          ]
        }
      ],
      labelList: [
        { name: '公司简介', fontColor: '#666666', iconColor: 'white' },
        { name: '人才招聘', fontColor: '#666666', iconColor: 'white' }
      ]
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
  },
  methods: {
    labelMouseenter(row) {
      row.iconColor = '#E84C3D'
    },
    labelMouseleave(row) {
      row.iconColor = 'white'
    },
    clickLabel(row) {
      if (row.name === this.labelList[1].name) {
        this.$router.push(`/taleRecr?pageName=${row.name}`)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
leftWidth = 39.8rem

.compaProfiCss {
  padding-bottom 4rem
  width 99.8rem
  background-color #333439
  font-size 0.8rem
  color white
  .contentMsgCss {
    padding 2rem 20rem 0 20rem
    width 59.8rem
    .leftMsgCss {
      float left
      width leftWidth
      .titleCss {
        font-size 2rem
        height 4rem
        line-height 4rem
        border-bottom 1px solid white
      }
      .msgCss {
        margin-top 1rem
        text-indent 1.6rem
      }
      img {
        margin-top 1rem
        max-width leftWidth
      }
      .titleAndMsgCss {
        width 100%
        background-color #E6E7E8
        .tAmItemCss {
          margin-top 1rem
          .tAmTitleCss {
            color #3E9EF5
            font-size 1rem
            height 1.8rem
            line-height 1.8rem
          }
          .tAmMsgCss {
            color #000
            text-indent 1.6rem
          }
        }
      }
    }
    .rightMsgCss {
      float left
      padding 2rem 0 0 8rem
      width 12rem
      .borderLineCss {
        width 1.5rem
        border 2px solid white
        margin-bottom 0.5rem
      }
      .labelContentCss {
        margin-top 2rem
        .labelCss {
          padding 0 1rem
          color #666666
          height 2rem
          line-height 2rem
          transition-property color, background-color
          transition-duration 0.5s, 0.5s
          cursor pointer // 鼠标指针变手
          .iconContentCss {
            float right
          }
        }
        .labelCss:hover {
          color #E84C3D
          background-color white
        }
      }
    }
  }
  .contentMsgCss:after { // 使 contentMsgCss 适应内部高度
    clear both
    content ''
    width 0
    height 0
    display block
    visibility hidden
  }
}
</style>
